<template>
    <div>
        <section class="pt-10 pb-10 md:pb-12 md:pt-20 lg:pt-36 bg-transparent w-full max-w-screen-xl mx-auto">
        <SectionTitle :msg="$t('our services')" class="my-9"/>
        <div class="w-full max-w-screen-xl mx-auto">
            <div v-for="(feature, index) in features" :key="index">
            <div 
            :data-aos="index %2== 0 ? 'fade-left' : 'fade-right'" 
            :data-aos-duration="500+(index*50)" 
            class="w-full px-0 md:grid md:grid-cols-2 lg:gap-x-12 xl:gap-x-36 md:gap-8 lg:px-6 my-8 text-right" >
            <div class="flex flex-col justify-center px-4 sm:px-6 md:pl-0 lg:px-0" 
            :class="index %2== 0 ? 'md:order-2' : 'md:order-1' ">
                <div>
                <p class="inline-flex items-center justify-center px-6 py-2 
                        text-sm font-bold tracking-wide 
                        text-white rounded-r-full rounded-tl-full bg-gradient-to-r
                        dark:from-transparent dark:to-primary-600 from-gray-300 to-secondary-600 " >
                    {{ feature.category }}
                </p>
                </div>
                <h3 class="mt-4 text-3xl font-bold text-gray-800 dark:text-slate-100 md:mt-6 sm:text-4xl lg:text-5xl" >
                    {{ feature.title }}
                </h3>
                <p class="mt-4 text-xl md:mt-6 text-gray-800 dark:text-slate-200 ">
                    {{ feature.desc }}
                </p>
            </div>
            <div class="relative w-full mt-10 md:mt-0 md:rounded-r-3xl lg:rounded-l-3xl pt-11/12" 
            :class="index %2== 0 ? 'md:order-1' : 'md:order-2' ">
                <img :src="feature.image"
                class="absolute inset-y-0 object-cover object-center w-full h-full shadow-xl md:rounded-r-3xl lg:rounded-l-3xl"
                />
            </div>
            </div>

            </div>
        </div>
    </section>
    </div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle.vue';
import { ref } from 'vue';
import { useI18n } from "vue-i18n";

export default {
    components:{
        SectionTitle,
    },
    setup(){
        const { t } = useI18n();
        const features = ref([
            {
                "category":t('main services'),
                "title":t('Freight Forwarding'),
                "desc":t('Freight forwarding companies coordinate the shipment of goods from one location to another, managing various aspects such as transportation, customs clearance, and documentation.'),
                "image":"https://www.qafila.com/wp-content/uploads/2019/02/Freight-forwarding-101.jpg",
            },
            {
                "category":t('main services'),
                "title":t('Warehousing and Distribution'),
                "desc":t('These companies offer storage solutions for goods and manage their distribution to customers or other destinations, ensuring timely delivery and efficient inventory management.'),
                "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSc83pb7sorF8QR26OJEs_yIWDXjyIwCcB7TBIWXjPZjA&s",
            },
            {
                "category":t('main services'),
                "title":t('Transportation Services'),
                "desc":t('Transportation companies provide various modes of transportation, including road, rail, air, and sea freight services, to move goods from one place to another.Customs Brokerage: Customs brokerage firms assist businesses in clearing goods through customs by handling documentation, compliance with regulations, and payment of duties and taxes.'),
                "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTEUon-FUxqwhD6FBITFEbXZHjvhZXa5uRJBTcaTWy7g&s",
            },
            {
                "category":t('main services'),
                "title":t('Supply Chain Management'),
                "desc":t('Supply chain management companies optimize the flow of goods, information, and finances from raw material suppliers to end customers, ensuring cost-effectiveness and operational efficiency.'),
                "image":"https://inc42.com/wp-content/uploads/2023/09/Glossary-Series-D2C-_-Supply-Chain-Management-social.jpg",
            },
            {
                "category":t('main services'),
                "title":t('Last-Mile Delivery'),
                "desc":t("Last-mile delivery services focus on delivering goods directly to the end customer's location, often utilizing local distribution centers or third-party delivery networks for efficient and timely delivery."),
                "image":"https://assets-global.website-files.com/62a991fe7443d71b2ed1afb0/63a99832ddf2277ef743f5e4_Last-Mile-Delivery.webp",
            },
            {
                "category":t('main services'),
                "title":t('Inventory Management'),
                "desc":t('Inventory management companies help businesses optimize their inventory levels, minimize stockouts, and reduce carrying costs through effective planning, forecasting, and monitoring of inventory levels.'),
                "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm6dnF9Ofld3JRngHukfYxlTAPcndcYFoMacEiSwwmD5VUBNGi2frg_RXX4r7LEK7wkM4&usqp=CAU",
            },
            {
                "category":t('main services'),
                "title":t('Reverse Logistics'),
                "desc":t('Reverse logistics providers manage the return and disposal of products, handling processes such as product recalls, repairs, recycling, or disposal in an environmentally responsible manner.'),
                "image":"https://ecampusontario.pressbooks.pub/app/uploads/sites/2010/2022/04/reverse_logistics.png",
            },
            {
                "category":t('main services'),
                "title":t('Packaging and Labeling'),
                "desc":t('Companies specializing in packaging and labeling offer services to design, produce, and apply packaging materials and labels to goods, ensuring they are properly protected and identified during transportation and storage.'),
                "image":"https://www.marketing91.com/wp-content/uploads/2017/10/Packaging-and-Labelling.jpg",
            },
            {
                "category":t('main services'),
                "title":t('Value-Added Services'),
                "desc":t('Value-added services include additional services such as product assembly, kitting, labeling, and quality inspection, aimed at enhancing the value of goods and meeting specific customer requirements.'),
                "image":"https://taylorwells.com.au/wp-content/uploads/2020/06/value-added-services1.jpg",
            },
        ])

        return {
            features,
        }
    }
}
</script>