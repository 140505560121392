<template>
    <div>
        <nav class="bg-transparent border-gray-200 dark:bg-transparent p-2">
            <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <router-link to="/" class="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src="../assets/images/logo.png" class="h-10" alt="Togits Logo" />
                </router-link>
                <div class="flex items-center md:order-2 space-x-1 md:space-x-0 rtl:space-x-reverse">
                    <button @click="toggleDarkMode()" type="button"
                        class="inline-flex items-center font-medium justify-center px-4 py-2 
                                text-sm dark:text-white rounded-lg cursor-pointer
                                text-gray-600 
                                hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white 
                                mx-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="16" height="16" viewBox="0 0 16 16">
                            <path v-if="isDarkMode"
                                d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
                            <path v-else
                                d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
                        </svg>
                    </button>
                    <button v-if="$i18n.locale === 'AR'"  @click="changeLocale('EN')" type="button" data-dropdown-toggle="language-dropdown-menu"
                        class="inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-gray-900 dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                        {{ $t("english") }}
                    </button>
                    <button v-if="$i18n.locale === 'EN'" @click="changeLocale('AR')" type="button" data-dropdown-toggle="language-dropdown-menu"
                        class="inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-gray-900 dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                        {{ $t("arabic") }}
                    </button>
                    <button data-collapse-toggle="navbar-language" type="button"
                        class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        aria-controls="navbar-language" aria-expanded="false">
                        <span class="sr-only">Open main menu</span>
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                </div>
                <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-language">
                    <ul
                        class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-transparent md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
                        <li>
                            <router-link to="/"
                                class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-primary md:p-0 dark:text-white md:dark:hover:text-primary dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{{$t('home')}}</router-link>
                        </li>
                        <li>
                            <router-link to="/about"
                                class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-primary md:p-0 dark:text-white md:dark:hover:text-primary dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{{$t('about')}}</router-link>
                        </li>
                        <li>
                            <router-link to="/services"
                                class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-primary md:p-0 dark:text-white md:dark:hover:text-primary dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{{$t('services')}}</router-link>
                        </li>
                        <li>
                            <router-link to="/contact"
                                class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-primary md:p-0 dark:text-white md:dark:hover:text-primary dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{{$t('contact us')}}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    </div>
</template>

<script>
import { useLocaleStore } from '@/store';
import { ref, onMounted } from 'vue';
import Cookies from 'js-cookie';
import AOS from 'aos'
import { useI18n } from 'vue-i18n';
import { i18n } from '@/main';
export default {
    setup() {
        const { t } = useI18n({ i18n });
        AOS.init();
        const store = useLocaleStore();
        const isDarkMode = ref(false)
        const changeLocale = (lang) => {
            store.changeLang(lang);
            window.location.reload()
        }
        onMounted(() => {
            const storedDarkModeValue = Cookies.get("isDarkMode");
            if (storedDarkModeValue === "true" || storedDarkModeValue === undefined) {
                isDarkMode.value = true;
                document.body.classList.add('dark');
                document.body.classList.add('dark-body');
                Cookies.set("isDarkMode", true);
            } else {
                isDarkMode.value = false;
                document.body.classList.remove('dark');
                document.body.classList.remove('dark-body');
                Cookies.set("isDarkMode", false);
            }
        })

        const toggleDarkMode = () => {
            isDarkMode.value = !isDarkMode.value;
            document.body.classList.toggle('dark', isDarkMode.value);
            document.body.classList.toggle('dark-body', isDarkMode.value);
            const newDarkModeValue = isDarkMode.value;
            Cookies.set("isDarkMode", newDarkModeValue);
        }

        return {
            store,
            changeLocale,
            toggleDarkMode,
            isDarkMode,
            t,
        };
    },
};
</script>