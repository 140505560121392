<template>
    <div>
        <section class="bg-transparent px-4 pt-6 pb-10 md:pt-8 md:pb-20 sm:px-6 lg:px-8 lg:pb-64">
            <div class="max-w-screen-xl mx-auto">
            <div>
                <SectionTitle msg="Our Services"/>
                <h1
                class="mt-6 text-4xl font-extrabold text-start text-gray-800 dark:text-secondary-300 sm:text-5xl md:text-6xl">
                {{ $t('General Business Global')  }} <span class="text-gray-500 dark:text-slate-200">{{ $t('hero_title') }}</span>
                </h1>
            </div>
            <div class="relative mt-6 md:mt-8 lg:mt-12 xl:mt-16">

                
                <div class="z-10 grid gap-x-8 lg:grid-cols-2 lg:absolute">
                <div>
                    <p
                    class="max-w-lg text-xl lg:mx-auto text-dark-300 sm:max-w-3xl dark:text-slate-100 text-start"
                    >
                    <span class="font-bold hover:text-primary-600 transition duration-300 ease-in-out">{{$t('General Business Global')}}</span>
                        {{ $t('hero paragraph') }}                    
                    </p>
                    <div class="mt-8 sm:flex sm:justify-start">
                    <router-link
                        class="flex items-center justify-center w-auto px-8 py-4 text-base font-semibold leading-snug transition ease-in-out 
                        bg-primary-700 text-white rounded-full h-14 duration-250 
                        hover:text-white hover:border-secondary-100  focus:outline-none 
                        hover:bg-secondary-800"
                        to="/contact"
                    >
                        {{ $t("Lets get started") }}
                    </router-link>
                    </div>
                </div>

                <div class="grid mx-auto mt-10 md:mt-12 sm:grid-cols-2 sm:gap-x-4 lg:mt-0 sm:mx-0" >
                    <img src="../assets/images/logistics-img.jpg"
                    class="object-cover object-center w-full shadow-xl rounded-3xl lg:h-96"
                    />
                    <img src="../assets/images/logistics.jpg"
                    class="hidden object-cover object-center w-full mt-6 shadow-xl rounded-3xl sm:block sm:mt-0 lg:h-96"
                    />
                </div>
                </div>
            </div>
            </div>
        </section>
</div></template>