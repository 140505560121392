<template>
    <div>
        <main class="bg-transparent dark:bg-transparent">
            <section class="px-4 py-12 md:py-16 sm:px-6 lg:px-8">
                <div class="max-w-screen-xl mx-auto">
                <div class="grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-16">
                    <div
                    class="flex flex-col items-start justify-between sm:items-center lg:items-start"
                    >
                    <div>
                        <div class="w-full text-left sm:text-center lg:text-left">
                            <SectionTitle :msg="$t('contact us')" />
                        </div>
                        <div
                        class="w-full mt-4 text-left md:mt-5 sm:text-center lg:text-left"
                        >
                        <h1 data-aos="fade-up" data-aos-duration="500"
                            class="text-4xl font-extrabold text-center text-gray-800 dark:text-slate-100 sm:text-5xl md:text-6xl"
                        >
                            {{$t('Get in touch')}}
                        </h1>
                        <p
                            data-aos="fade-up" data-aos-duration="800"
                            class="max-w-lg mx-auto mt-3 text-xl md:mt-5 text-gray-500 dark:text-slate-200 sm:max-w-2xl"
                        >
                            {{ $t('get in touch paragraph') }}.
                        </p>
                        </div>
                    </div>
                    <div
                        class="grid gap-6 mt-8 sm:mt-10 lg:mt-0 sm:grid-cols-2 sm:grid-rows-2 sm:gap-12"
                    >
                    <div data-aos="zoom-out" data-aos-duration="500">
                        <h5 class="text-lg font-bold text-gray-800 dark:text-slate-100">{{ $t("Sana'a, Yemen") }}</h5>
                        <p class="mt-1 text-base text-gray-500 dark:text-slate-300">
                            {{ $t('office address line1') }}
                            <br/> {{ $t('office address line2') }}
                        </p>
                        </div>
                        <div data-aos="zoom-out" data-aos-duration="750">
                        <h5 class="text-lg font-bold text-gray-800 dark:text-slate-100">{{ $t('call us') }}</h5>
                        <p class="mt-1 text-gray-500 rtl:text-end dark:text-slate-300" dir="ltr">
                            +86 188 5194 0739
                            <br />+967 735 325 626
                        </p>
                        </div>
                        <div data-aos="zoom-out" data-aos-duration="1000">
                        <h5 class="text-lg font-bold text-gray-800 dark:text-slate-100">{{ $t("work hours") }}</h5>
                        <p class="mt-1 text-base text-gray-500 dark:text-slate-200">
                            {{ $t('work days') }}
                            <br/>{{ $t('work days hours') }}
                        </p>
                        </div>
                        <div data-aos="zoom-out" data-aos-duration="1250">
                        <h5 class="text-lg font-bold text-gray-800 dark:text-slate-100">{{ $t('email us') }}
                        </h5>
                        <p class="mt-1 text-base text-gray-500 dark:text-slate-200">
                                info@gbg.business
                            <br/>sales@gbg.business
                        </p>
                        </div>
                    </div>
                    <div class="w-full mt-8 sm:mt-10 lg:mt-0" dir="ltr">
                        <h6 class="text-lg font-semibold text-center text-gray-600 dark:text-gray-100 sm:text-center lg:text-center" >
                            {{ $t('follow us') }}
                        </h6>
                        <div 
                            data-aos="zoom-in"
                            class="flex justify-center mt-3 space-x-4 sm:justify-center lg:justify-center" >
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 
                                ease-in-out rounded-full bg-gradient-to-r dark:from-primary-600 dark:to-primary-700 from-secondary-600 to-secondary-300 text-white"
                            href="#">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round" >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="4" y="4" width="16" height="16" rx="4" />
                            <circle cx="12" cy="12" r="3" />
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                            </svg>
                        </a>
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 
                        ease-in-out rounded-full bg-gradient-to-r dark:from-primary-600 dark:to-primary-700 from-secondary-600 to-secondary-300 text-white"
                            href="#">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 text-white"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                                d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                            />
                            </svg>
                        </a>
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 
                                ease-in-out rounded-full bg-gradient-to-r dark:from-primary-600 dark:to-primary-700 from-secondary-600 to-secondary-300 text-white"
                            href="#"
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" 
                        width="20" 
                        height="20" 
                        fill="currentColor" 
                        class="bi bi-twitter-x" 
                        viewBox="0 0 16 16"
                        >
                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                        </svg>
                        </a>
                        </div>
                    </div>
                    </div>
                    <div class="w-full mt-12 lg:mt-0">
                    <div class="w-full px-4 py-12 mx-auto shadow-xl rounded-3xl lg:mr-0 lg:ml-auto bg-dark-700 sm:p-16 lg:p-14 xl:p-16">
                        <form>
                        <div>
                            <label
                            for="name"
                            class="ml-0.5 text-gray-800 font-medium dark:text-slate-100 text-sm"
                            >{{ $t('name') }} *
                            </label>
                            <input
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Mohammed Ali"
                            class="w-full p-4 dark:placeholder-slate-200 mt-2 text-sm font-medium text-white transition duration-200 ease-in-out border-2 border-solid outline-none h-14 rounded-2xl dark:bg-gray-800 border-primary-600 dark:border-secondary-600 focus:border-primary-600 focus:outline-none"
                            required
                            />
                        </div>
                        <div class="mt-6">
                            <label
                            for="email"
                            class="ml-0.5 text-gray-800 font-medium dark:text-slate-100 text-sm"
                            >
                            {{ $t('your email') }} *
                            </label>
                            <input
                            id="email"
                            type="email"
                            name="email"
                            placeholder="mohammed@email.com"
                            class="w-full dark:placeholder-slate-200 p-4 mt-2 text-sm font-medium text-white transition duration-200 ease-in-out border-2 border-solid outline-none h-14 rounded-2xl dark:bg-gray-800 border-primary-600 focus:border-primary-600 dark:border-secondary-600 focus:outline-none"
                            required
                            />
                        </div>
                        <div class="mt-6">
                            <label
                            for="phone"
                            class="ml-0.5 text-gray-800 font-medium dark:text-slate-100 text-sm"
                            >
                            {{ $t('phone') }}
                            </label>
                            <input
                            id="phone"
                            type="text"
                            name="phone"
                            placeholder="(123) 456-789"
                            class="w-full p-4 mt-2 text-sm font-medium text-white dark:placeholder-slate-200 transition duration-200 ease-in-out border-2 border-solid outline-none h-14 rounded-2xl dark:bg-gray-800 border-primary-600 dark:border-secondary-600 focus:border-primary-600 focus:outline-none"
                            />
                        </div>

                        <div class="mt-6">
                            <label
                            for="message"
                            class="ml-0.5 text-gray-800 font-medium dark:text-slate-100 text-sm"
                            >
                            {{ $t('message') }} *
                            </label>
                            <textarea
                            id="message"
                            type="text"
                            name="message"
                            :placeholder="$t('your message')"
                            rows="5"
                            class="w-full dark:placeholder-slate-200 p-4 mt-2 text-sm font-medium text-white transition duration-200 ease-in-out border-2 border-solid outline-none rounded-2xl dark:bg-gray-800 border-primary-600 dark:border-secondary-600 focus:border-primary-600 focus:outline-none"
                            required
                            ></textarea>
                        </div>

                        <div class="flex justify-start mt-6">
                            <button
                            type="submit"
                            class="flex items-center justify-center w-auto px-8 py-4 text-base font-semibold leading-snug transition ease-in-out bg-white rounded-full h-14 duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-primary-600"
                            >
                            {{ $t("send message") }}
                            </button>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
                <div class="w-full text-left sm:text-center lg:text-left mt-4">
                            <SectionTitle :msg="$t('our location')" data-aos="zoom-in"/>
                </div>
                <div class="w-full" data-aos="zoom-out">
                    
                        <iframe
                        class="w-full mt-5 rounded-2xl object-cover h-96 shadow-lg" 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3848.6661571689206!2d44.23312461315258!3d15.28599106952236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1603c53058b229d9%3A0x50c26523f2855d6d!2z2YXYrNmF2Lkg2KfZhNi02YTYp9mEINin2YTYqtis2KfYsdmK!5e0!3m2!1sen!2s!4v1707727014756!5m2!1sen!2s" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
            </section>
            </main>
    </div>
</template>
<script>
import SectionTitle from '@/components/SectionTitle.vue';
export default {
    components:{
        SectionTitle,
    }
}
</script>