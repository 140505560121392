import { defineStore } from 'pinia'
import Cookies from "js-cookie";

export const useCounterStore = defineStore('counter', {
  state: () => {
    return { 
      count: 0,
    }
  },
  actions: {
    increment(val = 1) {
      this.count += val
    },
  },
  getters:{
    doubleCount: (state) => state.count * 2
  }
})
export const useLocaleStore = defineStore('locale', {
  state: () => {
    return { 
      lang: Cookies.get("locale") ||"EN",
    }
  },
  actions: {
    changeLang(lang="EN"){
      Cookies.set("locale",lang)
      this.lang = lang
    }
  },
})