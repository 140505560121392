<template>
    <div class="w-full text-start sm:text-center lg:text-start z-50" data-aos="fade-up" >
        <p
            class="inline-flex items-center justify-center 
            px-7 py-4 text-sm font-bold tracking-wide dark:text-white 
            rounded-2xl bg-gradient-to-r
            dark:from-secondary-600 dark:to-primary-700 from-primary-100
             to-secondary-600 text-white">
            {{ msg }}
        </p>
    </div>
</template>
<script>
export default {
    props: {
        msg: {
            type: String,
            required: true
        }
    }
}
</script>