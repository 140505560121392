<template>
    <div>
        <Services/>
    </div>
</template>
<script>

import Services from '@/components/Services.vue';
export default {
    components:{
        Services,
    }

}
</script>