<template>
    <div>
    <Hero/>
    <OurVision/>
    <ServicesVue/>
    </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import OurVision from '@/components/OurVision.vue';
import ServicesVue from '@/components/Services.vue';
export default {
    name: 'HomeView',
    components: {
        Hero,
        ServicesVue,
        OurVision,
    }
}
</script>
