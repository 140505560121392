import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createI18n } from 'vue-i18n'
import '@/axios'
import '@/assets/css/main.css'
import 'flowbite/dist/flowbite'
import 'aos/dist/aos'
import { createPinia } from 'pinia'

import 'aos/dist/aos.css'
import AR from '@/locale/ar.json'
import EN from '@/locale/en.json'

const app = createApp(App)
app.use(createPinia())
import { useLocaleStore } from '@/store/index'
const store = useLocaleStore()
export const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale : store.lang || "AR",
    messages:{
        EN: EN,
        AR: AR,
    }
})
app.use(i18n)
app.use(router)
app.mount('#app')